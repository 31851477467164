import { ButtonProps } from "@chakra-ui/react";
import { ReactElement } from "react";
import { FEATURES } from "utils/constants/featureConstants";
import { USER_REDUCER_ACTION_TYPES } from "../constants/contextConstants";

export enum REQUEST_TYPE {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export enum NavigationModuleTypes {
    REGULAR, BETA, COMING_SOON
}

export interface NavigationLinkOnHeaderValue {
    name: string
    path: string
    moduleText: string
    moduleColor: string
    type: NavigationModuleTypes
    feature?: FEATURES
}

export interface LoginBody {
    email: string;
    password: string;
}

export interface User {
    id?: string;
    name?: string;
    email?: string;
}

export interface Plan {
    id: string;
    name: string;
    description: string;
    price: number | string;
    priceId: string;
    popular?: boolean;
}

export interface FeatureMap {
    [key: string]: number;
}

export interface Subscription {
    id: string;
    customerId: string;
    status: string;
    expiresAt: string | null;
    subscriptionId: string | null;
    features: FeatureMap;
    hasPendingPayment: boolean;
}

export interface UserInfo extends User {
    isExpired?: boolean;
    subscription?: Subscription;
}

export interface UserCreationBody extends User {
    password?: string;
    passwordConfirmation?: string;
}

export interface PasswordChangeBody {
    currentPassword: string;
    password: string;
    passwordConfirmation: string;
}

export interface UserRecoveryBody {
    email: string;
    code: string;
    password: string;
    passwordConfirmation: string;
}

export interface UserContext {
    user: UserInfo;
}

export interface UserReducerAction {
    type: USER_REDUCER_ACTION_TYPES;
    payload: UserInfo;
}

export type ErrorDictionary = {
    title: string;
    description: string | null;
};

export type SuccessDictionary = {
    title: string;
    description: string | null;
};

export interface TelegramChat {
    id: string;
    name: string;
    chatId: string;
    status: string;
    delay: number;
    deliveryProbability: number;
    notDeliveredMessage: string;
    extraText: string;
    receiveReports: boolean;
    hideSoftwareLink: boolean;
}

export interface ExtraButton extends ButtonProps {
    callback: () => void;
    disbled?: boolean;
    label: string;
    colorScheme?: string;
    rightIcon?: ReactElement;
    closeOnAction?: boolean;
}

export type StrategyStatus = "ACTIVE" | "INACTIVE" | "PAPER_BET";

export type StrategyListItem = {
    id: string;
    name: string;
    status: StrategyStatus;
    openBets: number;
    bets: number;
    result: number;
    roi: number;
    todaysResult: number;
    averageDailyBets: number;
}

export interface FifaLeagueResponse {
    id: string;
    integrationId: number;
    name: string;
    link: string;
}

export interface FifaPlayerResponse {
    id: string;
    leagueId?: string;
    name: string;
}

export enum FifaMarketTypes {
    MATCH_ODDS = "MATCH_ODDS",
    ASIAN_GOAL_LINE = "ASIAN_GOAL_LINE"
}

export enum FifaMarketSubTypes {
    WINNER = "WINNER",
    DRAW = "DRAW",
    ASIAN_OVER_GOALS = "ASIAN_OVER_GOALS",
    ASIAN_UNDER_GOALS = "ASIAN_UNDER_GOALS"
}

export const FifaMarketSubTypesParent: { [key in FifaMarketSubTypes]: FifaMarketTypes } = {
    [FifaMarketSubTypes.WINNER]: FifaMarketTypes.MATCH_ODDS,
    [FifaMarketSubTypes.DRAW]: FifaMarketTypes.MATCH_ODDS,
    [FifaMarketSubTypes.ASIAN_OVER_GOALS]: FifaMarketTypes.ASIAN_GOAL_LINE,
    [FifaMarketSubTypes.ASIAN_UNDER_GOALS]: FifaMarketTypes.ASIAN_GOAL_LINE
};

export enum FifaRuleTypes {
    MINIMUM_ODDS = "MINIMUM_ODDS",
    MINIMUM_JUICE = "MINIMUM_JUICE",
    MINIMUM_PROBABILITY = "MINIMUM_PROBABILITY",
    MINIMUM_MATCHES = "MINIMUM_MATCHES"
}

export enum FifaMatchupTypes {
    VS_ANYONE = "VS_ANYONE",
    VS_EACH_OTHER = "VS_EACH_OTHER"
}

export enum FifaStrategyScopeTypes {
    TWENTY_FOUR_HOURS = "TWENTY_FOUR_HOURS",
    THREE_DAYS = "THREE_DAYS",
    ONE_WEEK = "ONE_WEEK",
    TWO_WEEKS = "TWO_WEEKS",
    ONE_MONTH = "ONE_MONTH",
    TWO_MONTHS = "TWO_MONTHS"
}

export interface MarketType {
    marketType: FifaMarketTypes;
    marketSubTypes: FifaMarketSubTypes[];
}

export enum RuleValueFormatTypes {
    INTEGER = "INTEGER",
    PERCENTAGE = "PERCENTAGE",
    ODD = "ODD"
}

export interface RuleTypeDetail {
    type: FifaRuleTypes;
    minValue: number;
    maxValue: number;
    defaultValue: number;
    format: RuleValueFormatTypes;
}

export interface StrategyParams {
    leagues: FifaLeagueResponse[];
    marketTypes: MarketType[];
    players: FifaPlayerResponse[];
    ruleTypes: RuleTypeDetail[];
    matchupTypes: FifaMatchupTypes[];
    scopeTypes: FifaStrategyScopeTypes[];
}

export interface Rule {
    id?: string;
    type: string;
    value: number;
}

export interface Scope {
    id?: string;
    matchup: string;
    type: string;
    rules: Rule[];
}

export interface StrategyCreationBody {
    id?: string;
    name: string;
    marketType: string | null;
    marketSubTypes: string[];
    leagues: string[];
    excludedPlayers: string[];
    scopes: Scope[];
}

export type Option = {
    value: string;
    label: string;
};

export interface Bet {
    id: string;
    isPaperBet: boolean;
    strategyName: string;
    leagueName: string;
    homePlayerName: string;
    awayPlayerName: string;
    matchTime: string;
    betTime: string;
    candidate: string;
    odds: number;
    status: string;
    score: string | null;
    handicap: number | null;
    profit: number | null;
}

export interface PaginatedResponse {
    totalPages: number;
    totalElements: number;
    size: number;
    content: Bet[];
    number: number;
    sort: {
        empty: boolean;
        sorted: boolean;
        unsorted: boolean;
    };
    first: boolean;
    last: boolean;
    numberOfElements: number;
    pageable: {
        pageNumber: number;
        pageSize: number;
        sort: {
            empty: boolean;
            sorted: boolean;
            unsorted: boolean;
        };
        offset: number;
        paged: boolean;
        unpaged: boolean;
    };
    empty: boolean;
}

export interface BetQueryFilters {
    openBets: boolean;
    closedBets: boolean;
    realBets: boolean;
    paperBets: boolean;
    league: string[];

    [key: string]: boolean | string[];
}

export interface BetStatistics {
    openBets: number;
    possibleProfitOnOpenBets: number;
    dayStatistics: {
        numberOfBets: string;
        profit: number;
        roi: number;
    };
    monthStatistics: {
        numberOfBets: string;
        profit: number;
        roi: number;
    };
    last12DaysProfit: Array<{
        date: string;
        profit: number;
    }>;
    last12MonthsProfit: Array<{
        startDate: string;
        endDate: string;
        profit: number;
    }>;
}

export interface AutoBettor {
    id: string;
    user: User;
    integrationId: string;
    name: String;
    status: string;
    createdAt: string;
}

export interface LeagueTrend {
    league: FifaLeagueResponse;
    trend: number[];
}
